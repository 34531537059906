import React from "react";
import FooterPageLinks from "./footer-page-links";
import Icon from "./icon";

// Assets.
import logo from "../images/daans-administratie-logo.svg";

const Footer = () => (
  <footer
    className="footer"
    style={{
      background: "#eeebe6",
      padding: "1em"
    }}
  >
    <div className="grid">
      <div className="footer-summary">
        <img src={logo} width="250" alt="" style={{ marginBottom: ".65em" }} />
        <p>
          Ik ben Simon Daans en ik ga ervoor zorgen dat jij je weer kunt
          focussen op hetgeen waarvoor je ooit ondernemer werd. Weten wie mijn
          partners in crime zijn? Kijk op mijn{" "}
          <a href="https://www.linkedin.com/in/simon-daans-217842108/">
            LinkedIn profiel
          </a>
          .
        </p>
        <a
          href="https://www.linkedin.com/in/simon-daans-217842108/"
          className="social linkedin"
        >
          <Icon
            name="linkedin"
            title="Bekijk Daans Administratie op LinkedIn"
          />
        </a>
      </div>

      <FooterPageLinks frontpage="true" />

      <div className="footer-contact">
        <h3>Contact</h3>
        <p>
          Daans
          <br />
          Nieuwstraat 46  
          <br />
          5571 BD Bergeijk  
          <br />
          <a href="tel:0031614367159" className="phone">
            +31 (0)6 14 36 71 59
          </a>
        </p>
        <p>
          <a href="mailto:simon@daans.nu">simon@daans.nu</a>
        </p>
      </div>
    </div>
  </footer>
);

export default Footer;
